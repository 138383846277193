import homemng from '../vacancies/Home Manager .pdf'
import team from '../vacancies/TEAM LEADER job spec .pdf'

import responsible from '../vacancies/Responsible Individual .pdf'
import deputy from '../vacancies/DEPUTY MANAGER JOB SPEC .pdf'


export const polfiles = [
    {id: 1,
     title: " Home Manager",
     file: homemng
    },

    {id: 2,
     title: "Team Leader",
     file: team
    },

    

    {id: 3,
        title: "Responsible Individual",
        file: responsible
    },

    {id: 4,
        title: "Deputy Manager",
        file: deputy
    }
]
export default polfiles